import React, { useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../route';
import store from '../../redux/store';
import { setApp } from '../../redux/actions/app';
import { ChevronLeft } from '@mui/icons-material'; // Import the icon
import { logo } from '../../../config/assets/images';

const LeftBar = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    if (!props.user.id) return null;

    /* Check if user has apps, if not create first */
    if (!props.user.apps || props.user.apps.length === 0) {
        navigate('/create-app');
        return null;
    }

    /* Check if user has selected an app, if not select first */
    if (!props.app.branding && props.user.apps) {
        store.dispatch(setApp(props.user.apps[0]));
        return null;
    }

    const renderTitle = ({ icon, breadcrumb, key, active, path }) => {
        if (!active) { return null; }

        let isActive = false;
        if (path.includes('add-ons')) {
            const searchParams = new URLSearchParams(location.search);
            const addonName = searchParams.get('name');
            isActive = addonName === key;
        } else {
            isActive = location.pathname.includes(path);
        }

        return (
            <div className='nav-link'>
                <Link
                    to={path}
                    className={`title-leftBar left-bar-icon ${isActive ? "isActive" : ""}`}>
                    {icon}
                    {!isCollapsed && <h5 className='text-left left-bar-text title'>{breadcrumb}</h5>}
                </Link>
            </div>
        );
    };

    return (
        <div className={`left-bar ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="logo-container">
                <img src={logo} alt="Logo" />
            </div>
            <div className='toggle-div'>    
                <button onClick={() => setIsCollapsed(!isCollapsed)} className={`toggle-button ${isCollapsed ? 'collapsed' : ''}`}>
                    <ChevronLeft className="icon" />
                </button>
            </div>
            <div className="align-center">
                <div className='container-box-left-bar'>
                    {ROUTES.map(route => {
                        if (route.children) {
                            return (
                                <div key={route.key}>
                                    {route.children.map(subRoute => (
                                        <div key={subRoute.key}>
                                            {renderTitle(subRoute)}
                                        </div>
                                    ))}
                                </div>
                            );
                        }
                        return null;
                    })}
                    {/* Apps */}
                    <div className='margin'>
                        <h6 className='grey-2'> Apps </h6>
                        {ROUTES.find(route => route.key === 'app').children.find(route => route.key === 'add-ons').children
                            .filter(addOn => !addOn.system)
                            .map(addOn => (
                                renderTitle({...addOn, path: `add-ons?name=${addOn.key}`})
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.user,
        router: state.router,
        app: state.app
    };
};

export default connect(mapStateToProps)(LeftBar);
